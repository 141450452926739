import Text, { Props as TextProps } from '@packages/common/src/components/Text'
import * as React from 'react'
import { CmpStyles } from '@packages/common/src/providers/style/types'
import focusSelector from '@packages/common/src/themes/focus-selector'
import NextLink, { LinkProps } from 'next/link'

type Props = TextProps & {
  to: LinkProps['href']
  target?: string
  onClick?: React.EventHandler<React.MouseEvent<HTMLAnchorElement>>
  accent?: 'default' | 'web' | 'inherit'
  textDecoration?: 'none' | 'underline'
}

export const styles: CmpStyles = {
  box: ({
    accent = 'inherit',
    textDecoration,
    theme: { components, borderRadius },
  }) => ({
    // @ts-ignore
    ...components.Link[accent],
    textDecoration: textDecoration || 'underline',
    cursor: 'pointer',
    [focusSelector]: {
      borderRadius: borderRadius.normal,
    },
  }),
}

const LinkWithPassedProps = React.forwardRef<HTMLAnchorElement, Props>(
  ({ onClick, href, target, style, ...props }, ref) => (
    <Text
      as="a"
      style={style ? [styles.box, style] : styles.box}
      nativeProps={{
        target,
        href,
        onClick,
        ref,
        rel: target === '_blank' ? 'noreferrer' : undefined,
      }}
      {...props}
    />
  )
)

const Link = ({ to, ...props }: Props) => (
  <NextLink href={to} passHref legacyBehavior>
    <LinkWithPassedProps {...props} />
  </NextLink>
)

export default Link
