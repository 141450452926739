import * as React from 'react'
import { FormikProps } from 'formik'

const isFocusable = (el: any): el is HTMLElement => !!el.focus

const findClosestFocusable = (el: Element) => {
  // prefer label, as it seems to not trigger autocomplete.
  let focusEl: HTMLElement | null = el.closest('label')

  if (!focusEl && isFocusable(el)) {
    focusEl = el
  }

  return focusEl
}

const getFirstErrorPath = (object: any): string => {
  if (!object || typeof object !== 'object') {
    return ''
  }

  const currentKey = Object.keys(object)[0]

  if (currentKey === undefined) return ''

  const nested = getFirstErrorPath(object[currentKey])

  return `${currentKey}${nested ? '.' : ''}${nested}`
}

const useScrollToValidationError = (
  formRef: React.RefObject<HTMLFormElement | null>,
  formik: FormikProps<any>
) => {
  const submitCountOnLastFocus = React.useRef(formik.submitCount)
  const firstErrorPath = getFirstErrorPath(formik.errors)

  const inputs = formRef.current ? [...formRef.current.elements] : []
  const firstInvalidInput = inputs.find(
    input => (input as HTMLInputElement).name === firstErrorPath
  )
  const focusEl =
    firstErrorPath &&
    firstInvalidInput &&
    findClosestFocusable(firstInvalidInput)

  React.useEffect(() => {
    if (formik.isValidating || formik.isValid) return

    if (
      focusEl &&
      submitCountOnLastFocus.current !== formik.submitCount &&
      !formik.isValid
    ) {
      focusEl.focus()
      submitCountOnLastFocus.current = formik.submitCount
    }
  }, [formik.submitCount, formik.isValidating, formik.isValid, focusEl])
}

export default useScrollToValidationError
